let randomCommentArr = [
  "The article did a horrible job of inlining those tweets",
  "So the fact that the source isnt there is proof that copyright prevented it I may have a tiger repellent rock to sell youpYou need to provide evidence that the source of the Mac OS Oracle or DB2 would be there if copyright didnt exist",
  "ECMAScript sounds like some sort of horrible disease",
  "One would hope you're storing the passwords as salted hashes (preferably bcrypt) so that passwords won't get leaked no matter how bad you are at database security",
  "It was irresponsible of Palm to do so. That doesn't mean it isn't user-hostile and slightly evil for Apple to intentionally break syncing with a third-party device",
  "It gives me a blog post named When Startup Ideas Go From Bad to Terrible",
  "I think I did wear it when we were visiting galleries for Artix the horrible startup we tried before Viaweb because I found a couple Artix business cards in the pocket",
  "Stop filling up the comment threads with this crap",
  "This is from that terrible book by Nesheim I wouldnt trust anything he says",
  "Do you feel its evil for a company giving away say a car as a prize to disclose the brand of the car",
  "The website built on spam to extract money out of desperate guys I could write a blog post on this Badoo is legitimately deception across the board",
  "A lawyer sending a copyright claim over a trademark dispute for example would have to argue that they are an idiot about the law I would think",
  "Because the judge is an idiot and thats not what the banks lawyers requested",
  "Yeah thats why I stopped using them their collection agencies were horrible"
];
export default randomCommentArr;
