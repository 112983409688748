const list = [
  [
    {
      author: "pg",
      sentiment: -1.0,
      ranking: 0,
      time: 1172085056,
      comment: "This is from that terrible book by Nesheim I wouldnt trust anything he says"
    },
    {
      author: "pg",
      sentiment: -1.0,
      ranking: 0,
      time: 1330377310,
      comment: "They get shared among YC alumni and acquirers know that which seems to mitigate the worst abuses"
    },
    {
      author: "pg",
      sentiment: -1.0,
      ranking: 0,
      time: 1309042471,
      comment: "Do you feel its evil for a company giving away say a car as a prize to disclose the brand of the car"
    },
    {
      author: "pg",
      sentiment: -1.0,
      ranking: 0,
      time: 1250537184,
      comment: "I think I did wear it when we were visiting galleries for Artix the horrible startup we tried before Viaweb because I found a couple Artix business cards in the pocket"
    },
    {
      author: "pg",
      sentiment: -1.0,
      ranking: 1,
      time: 1189118584,
      comment: "Id do it just to try flying on an EclipsepThat flash on their frontpage was shocking though Thank God for adblock"
    },
    {
      author: "pg",
      sentiment: -0.875,
      ranking: 1,
      time: 1296455955,
      comment: "Brutal honesty doesnt push things forward any faster than honesty"
    },
    {
      author: "pg",
      sentiment: -0.8,
      ranking: 0,
      time: 1381779811,
      comment:
        "Please donx27t use uppercase for emphasispa hrefhttpycombinatorcomnewsguidelineshtml relnofollowhttpx2Fx2Fycombinatorcomx2FnewsguidelineshtmlapItx27s especially unnecessary when replying to someone who already understands the point youx27re making"
    },
    {
      author: "pg",
      sentiment: -0.8,
      ranking: 0,
      time: 1254102795,
      comment:
        "At the moment itspprecode commentgravity 8 def commentrank s o scorefn realscore o gravity commentgravity let base scorefn s 1 if 62 base 0 expt base 8 base expt itemage s 1 60 gravitycodepre"
    },
    {
      author: "pg",
      sentiment: -0.8,
      ranking: 0,
      time: 1208983982,
      comment: "Stop filling up the comment threads with this crap"
    }
  ],
  [
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 0,
      time: 1344470293,
      comment: "If appnet turned evil people would stop paying and then appnet would go broke Thats the theory anyway"
    },
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 1,
      time: 1243832405,
      comment: "The convenient thing about being evil is that you dont have to check for things like this You just do whatever you want"
    },
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 0,
      time: 1427314616,
      comment: "And then evil ISPs will just not join"
    },
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 0,
      time: 1406848194,
      comment: "People who complain about premining probably also think VC is evil because their Radeon card isnx27t sufficient to participate in seed rounds"
    },
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 0,
      time: 1383089858,
      comment: "Assuming that browsers have implemented Web Crypto how do you know youx27re using it and not an evil polyfill"
    },
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 4,
      time: 1336665968,
      comment: "Looks like they copied the limitations of EC2 almost exactly Boring"
    },
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 0,
      time: 1290463871,
      comment: "This is based on client certificates and thus is doomed by terrible browser UIs"
    },
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 0,
      time: 1376687468,
      comment: "I would say morality but I realize thatx27s an invalid argument It seems like the government shouldnx27t be allowed to profit from issuing student loans especially bad ones"
    },
    {
      author: "wmf",
      sentiment: -1.0,
      ranking: 3,
      time: 1384897423,
      comment: "Worst landing page ever You canx27t quothackquot conversion by refusing to tell people what theyx27re signing up for"
    }
  ],
  [
    {
      author: "hga",
      sentiment: -1.0,
      ranking: 0,
      time: 1283969616,
      comment: "This is California noncompetes presumptively evil there although I had the impression his sort of job might be in the category where they are allowed"
    },
    {
      author: "hga",
      sentiment: -1.0,
      ranking: 10,
      time: 1287406939,
      comment: "Heres one nasty detail that should be taken into account some fraction of parents will just confiscate the payments from their children"
    },
    {
      author: "hga",
      sentiment: -1.0,
      ranking: 0,
      time: 1378313745,
      comment:
        "They had it in the x2780spHelped that we were fighting an in your face existential threat the Evil Empire AKA the Soviet Union with their 45000 sic nuclear warhead inventory peak in 1988 a hrefhttpsenwikipediaorgwikiRussiaandweaponsofmassdestruction relnofollowhttpsx2Fx2Fenwikipediaorgx2Fwikix2FRussiaandweaponsofmassdesa I in fact supplied a reference for a student acquaintance applying for a job at the NSA in that year"
    },
    {
      author: "hga",
      sentiment: -0.91,
      ranking: 1,
      time: 1275942449,
      comment: "Would you say that postSarbox VC looks very ugly"
    },
    {
      author: "hga",
      sentiment: -0.805,
      ranking: 0,
      time: 1419169049,
      comment: "A lot of that a lot of NIH and a lot of bad behavior and very bad management"
    },
    {
      author: "hga",
      sentiment: -0.8,
      ranking: 0,
      time: 1418658016,
      comment:
        "I was unclear in the quotwe look rather Europeanquot bit thatx27s for all violent crime andx2For homicides not just police caused ones for which as I understand the statistics are iffy ie therex27s no requirement to report them"
    },
    {
      author: "hga",
      sentiment: -0.8,
      ranking: 3,
      time: 1370724282,
      comment: "Doubtful these are pressurized water reactors not boiling ones an inherently safer design"
    },
    {
      author: "hga",
      sentiment: -0.7,
      ranking: 0,
      time: 1441498201,
      comment: "It would seem things are getting a bit ugly at Intuit Any educated guesses why"
    },
    {
      author: "hga",
      sentiment: -0.7,
      ranking: 1,
      time: 1441331425,
      comment: "Paranoia Wouldnx27t things get rather ugly if you accidentally fed a voltage divider the 18V in that circuit"
    }
  ],
  [
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 2,
      time: 1198322055,
      comment: "Worst science of the year"
    },
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 0,
      time: 1321923865,
      comment: "Of course Motorola will build Android phones There is nothing shocking about that"
    },
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 0,
      time: 1324426452,
      comment: "You are also evil if my memory of LOTR serves me correctly "
    },
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 5,
      time: 1260050506,
      comment: "I thought the consensus was that OpenDNS is evil censorship and all"
    },
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 1,
      time: 1216509477,
      comment: "I think it is not about the design of the search engine but about judging the design of the pages that are being indexed Could kill a lot of nasty spammy pages on the spot"
    },
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 1,
      time: 1299669384,
      comment: "So how does the police distinguish between a harmless session cookie and an evil tracking cookie"
    },
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 3,
      time: 1217612256,
      comment: "Boring"
    },
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 1,
      time: 1242128908,
      comment: "I see your point but I dont think the questions were particularly insane"
    },
    {
      author: "Tichy",
      sentiment: -1.0,
      ranking: 0,
      time: 1276538746,
      comment: "insulting"
    }
  ],
  [
    {
      author: "mikeash",
      sentiment: -1.0,
      ranking: 3,
      time: 1367092446,
      comment:
        "I can never remember what its called but its very annoying when you need to find it to disable itpThese days clang will tell you what a warning is called any time it gives you one If you ever need to disable a warning make it happen which you probably already did otherwise why would you need to disable it and then just read the error message to see what flag to use to disable it"
    },
    {
      author: "mikeash",
      sentiment: -1.0,
      ranking: 0,
      time: 1431451895,
      comment: "I learned something too I knew we werenx27t alone but I just assumed we were the worst Guess not"
    },
    {
      author: "mikeash",
      sentiment: -1.0,
      ranking: 0,
      time: 1394640939,
      comment:
        "And thus I could understand even if still think it was terrible if one place had gotten ahold of this story and run with itpBut itx27s been floating around for idaysi now iEverybodyi is reporting on it over and over again"
    },
    {
      author: "mikeash",
      sentiment: -1.0,
      ranking: 1,
      time: 1358477358,
      comment: "ECMAScript sounds like some sort of horrible disease"
    },
    {
      author: "mikeash",
      sentiment: -1.0,
      ranking: 0,
      time: 1426461650,
      comment: "I guess therex27s no point in trying to continue the discussion since Ix27m quotnormalizing state violencequot somehow and I guess that makes me evil for considering the facts"
    },
    {
      author: "mikeash",
      sentiment: -1.0,
      ranking: 1,
      time: 1406648257,
      comment: "That is simultaneously the worst and the clearest car analogy Ix27ve ever seen"
    },
    {
      author: "mikeash",
      sentiment: -1.0,
      ranking: 0,
      time: 1376597634,
      comment: "Donx27t be offended Ix27m insulting iall of you equallyi"
    },
    {
      author: "mikeash",
      sentiment: -0.8,
      ranking: 1,
      time: 1425656886,
      comment: "It could be a Bitcoin miner put in by people who donx27t understand how worthless that would be"
    },
    {
      author: "mikeash",
      sentiment: -0.8,
      ranking: 1,
      time: 1356038973,
      comment: "Meanwhile the rest of us have to buy spatulas from nonspecialty stores like an idiot Maybe its time for someone to bring Spatula City into the internet age"
    }
  ],
  [
    {
      author: "gaius",
      sentiment: -1.0,
      ranking: 0,
      time: 1395956539,
      comment: "Do know evil"
    },
    {
      author: "gaius",
      sentiment: -1.0,
      ranking: 0,
      time: 1333472997,
      comment: "I can see that it is from the hysterical downvoting "
    },
    {
      author: "gaius",
      sentiment: -1.0,
      ranking: 17,
      time: 1390648752,
      comment: "iUpon my release from the hospital also not a function of my recovery but as a result of my expulsion from the CollegeipDamn thatx27s bleak"
    },
    {
      author: "gaius",
      sentiment: -1.0,
      ranking: 0,
      time: 1258583704,
      comment: "In the UK 3 already have a dedicated SkypeoverWifi handset I believe it wasnt terribly successfulp3 is the name of a mobile telco in the UK"
    },
    {
      author: "gaius",
      sentiment: -1.0,
      ranking: 1,
      time: 1392455303,
      comment:
        "Nowadays people see something they donx27t like on Twitter and call the police itx27s insane Of course itx27s on the cops themselves too for wasting their time and taxpayerx27s money on it The correct response is as it always have been drop x27em in a killfile and move on with your lifepActually using the word quotkillfilequot probably puts you on a terrorist watchlist"
    },
    {
      author: "gaius",
      sentiment: -0.9,
      ranking: 1,
      time: 1429350840,
      comment: "I am baffled that the FreeBSD movement would put their name on something like this Horrifying"
    },
    {
      author: "gaius",
      sentiment: -0.8,
      ranking: 0,
      time: 1331132012,
      comment: "There are two kinds of programming languages those that people hate and those that noone uses"
    },
    {
      author: "gaius",
      sentiment: -0.8,
      ranking: 1,
      time: 1443732799,
      comment: "Have you considered the possibility that your coworkers hate it and by extension you"
    },
    {
      author: "gaius",
      sentiment: -0.8,
      ranking: 0,
      time: 1310746539,
      comment: "There are two kinds of languages Those people hate and those noone uses"
    }
  ],
  [
    {
      author: "rdl",
      sentiment: -1.0,
      ranking: 0,
      time: 1352448499,
      comment: "Yeah thats why I stopped using them their collection agencies were horrible"
    },
    {
      author: "rdl",
      sentiment: -1.0,
      ranking: 0,
      time: 1373350928,
      comment: "I see your boring sewage vehicles and raise you an xray trucka hrefhttpwwwyoutubecomwatchv1DPd3ihR5Lo relnofollowhttpx2Fx2Fwwwyoutubecomx2Fwatchv1DPd3ihR5Loa"
    },
    {
      author: "rdl",
      sentiment: -1.0,
      ranking: 0,
      time: 1365119957,
      comment: "Oakland PD is also one of the worst departments in the Bay Area although BART PD and EPA PD take the cake"
    },
    {
      author: "rdl",
      sentiment: -1.0,
      ranking: 18,
      time: 1296113592,
      comment: "Khao Lak Thailand for another 12 hours then boring Palo Alto or San Francisco "
    },
    {
      author: "rdl",
      sentiment: -1.0,
      ranking: 0,
      time: 1352322546,
      comment: "That would be horrible to try to build a business on if you werent the rightsholder though"
    },
    {
      author: "rdl",
      sentiment: -1.0,
      ranking: 0,
      time: 1368583733,
      comment: "HN hates blogspam so Id be reluctant to write a 13 para intro and then cite a post like this then submit it to HN Although I guess if it added context it wouldnt be horrible"
    },
    {
      author: "rdl",
      sentiment: -0.9,
      ranking: 0,
      time: 1378522088,
      comment:
        "I want something ismalleri than a Model S for now Driving a Model S into SF and dealing with parkingx2Fetc looks like a pain Ix27ve driven a Crown Vic 745i etc and I hated it there Ix27ll solve this by being in ruralish WA by the time I get my Model S AWD I also tend to dislike SUVs which arenx27t trucks"
    },
    {
      author: "rdl",
      sentiment: -0.85,
      ranking: 2,
      time: 1378401881,
      comment: "Is Overdrive the library ebook system I see everywhere intentionally painful to use to deter users or just horribly implemented"
    },
    {
      author: "rdl",
      sentiment: -0.8,
      ranking: 5,
      time: 1307424214,
      comment: "This is one of those cases where I really hate NDAs"
    }
  ],
  [
    {
      author: "icebraining",
      sentiment: -1.0,
      ranking: 1,
      time: 1406456876,
      comment: "That sounds terrible Whatx27s keeping builders from offering brick construction"
    },
    {
      author: "icebraining",
      sentiment: -1.0,
      ranking: 0,
      time: 1410400723,
      comment: "Howx27s the quality of your provider Ix27ve been using MegaVOIP also fed up with Skype and itx27s getting terrible"
    },
    {
      author: "icebraining",
      sentiment: -1.0,
      ranking: 0,
      time: 1368703311,
      comment: "No I dont think any of those actions are evil"
    },
    {
      author: "icebraining",
      sentiment: -1.0,
      ranking: 3,
      time: 1331990834,
      comment:
        "iBut the evil aspect of Napster wasnt that it was P2P it was that it didnt return any money to the creatorsipNo its because it forfeited the control the labels had over the music Theyve showed again and again that they dont mind losing money to maintain that control"
    },
    {
      author: "icebraining",
      sentiment: -0.9,
      ranking: 0,
      time: 1331637756,
      comment:
        "So the fact that the source isnt there is proof that copyright prevented it I may have a tiger repellent rock to sell youpYou need to provide evidence that the source of the Mac OS Oracle or DB2 would be there if copyright didnt exist"
    },
    {
      author: "icebraining",
      sentiment: -0.9,
      ranking: 0,
      time: 1429758397,
      comment: "Nobody said or implied that they have any equivalence Please try to follow the logic of the arguments being made before being outraged at them"
    },
    {
      author: "icebraining",
      sentiment: -0.85,
      ranking: 1,
      time: 1310690078,
      comment: "It gives me a blog post named When Startup Ideas Go From Bad to Terrible"
    },
    {
      author: "icebraining",
      sentiment: -0.8333333333,
      ranking: 10,
      time: 1309231237,
      comment: "Of course evil is subjective and therefore meaningless Whos to say the Patriot Act is evil or not"
    },
    {
      author: "icebraining",
      sentiment: -0.8,
      ranking: 0,
      time: 1444668504,
      comment: "The problem is not discussing the usage of shared resources itx27s advocating the outing of someone which might expose them to violent reactions"
    }
  ],
  [
    {
      author: "pyre",
      sentiment: -1.0,
      ranking: 0,
      time: 1305901964,
      comment: "Though carbs are evil implies that it affects everyone"
    },
    {
      author: "pyre",
      sentiment: -1.0,
      ranking: 0,
      time: 1290226085,
      comment:
        "Colorschemes that use italics are hitormiss for me Sometimes I prefer to use Terminus which doesnt have an italic typeface but vim will try and emulate one horribly Or when using DejaVu Sans Mono at 9pt because the lowercase d has its tall part cut off when in italics causing it to look like an a"
    },
    {
      author: "pyre",
      sentiment: -1.0,
      ranking: 1,
      time: 1290449089,
      comment:
        "Is Apache hurting the way because you can passwordprotect URIs Is 403 Forbidden the evil part of the HTTP specpI somehow dont think that this information requires a login is the death of the web"
    },
    {
      author: "pyre",
      sentiment: -1.0,
      ranking: 1,
      time: 1342678695,
      comment: "The article did a horrible job of inlining those tweets"
    },
    {
      author: "pyre",
      sentiment: -0.8,
      ranking: 0,
      time: 1334422377,
      comment:
        "pprecode 62 So I could see Kickstarter considering it a violation 62 of the Dont post obscene hateful or objectionable 62 content If you do we will remove it and suspend you 62 guidelinecodepreI hate speculation like this If Kickstarter considered it in violation of that part of their TOS then why not explicitly state so rather than saying that she was encouraging spam and that spam is in violation of the TOS Chances are that they did not consider it a violation of those terms"
    },
    {
      author: "pyre",
      sentiment: -0.8,
      ranking: 0,
      time: 1278577588,
      comment:
        "The video itself convinced me no to go to his site He opens his site in the browser just to get hung up on the Sign up for my newsletter popup that runs when the site loadspprecode Hey Watch me demo this using my websight Look at this annoying signup prompt that you get when you go to my websightcodepre"
    },
    {
      author: "pyre",
      sentiment: -0.8,
      ranking: 0,
      time: 1366447320,
      comment: "A lawyer sending a copyright claim over a trademark dispute for example would have to argue that they are an idiot about the law I would think"
    },
    {
      author: "pyre",
      sentiment: -0.8,
      ranking: 0,
      time: 1253917235,
      comment: "Because the judge is an idiot and thats not what the banks lawyers requested"
    },
    {
      author: "pyre",
      sentiment: -0.8,
      ranking: 1,
      time: 1271445113,
      comment: "62 iwhere there is smoke there is fireipI will always hate that phrase because its one of the phrases my dad used to prove that Saddam had weapons of mass destruction"
    }
  ]
];

export default list;
