let listUsers = [
  { author: "pg", sentiment: 0.090097317, num_comments: 9982 },
  { author: "wmf", sentiment: 0.0686600718, num_comments: 8635 },
  { author: "hga", sentiment: 0.0815668877, num_comments: 7062 },
  { author: "Tichy", sentiment: 0.0854002161, num_comments: 9775 },
  { author: "mikeash", sentiment: 0.0727129687, num_comments: 9313 },
  { author: "gaius", sentiment: 0.0924706808, num_comments: 8590 },
  { author: "rdl", sentiment: 0.1126716757, num_comments: 8200 },
  { author: "icebraining", sentiment: 0.072923778, num_comments: 9629 },
  { author: "pyre", sentiment: 0.0604344321, num_comments: 8001 }
];
export default listUsers;
